import React from 'react'
import styled from 'styled-components'
import ReactHighlight from 'react-highlight.js'
import { Props } from './index'

const StyleWrapper = styled.div`
  .hljs {
    display: block;
    overflow-x: auto;
    padding: 1em;
    // background: #23241f;
    background: #333;
    border-radius: 3px;
  }
  .hljs,
  .hljs-tag,
  .hljs-subst {
    color: #f8f8f2;
    color: #fff;
  }
  .hljs-strong,
  .hljs-emphasis {
    color: #a8a8a2;
  }
  .hljs-bullet,
  .hljs-quote,
  .hljs-number,
  .hljs-regexp,
  .hljs-literal,
  .hljs-link {
    color: #ae81ff;
  }
  .hljs-code,
  .hljs-title,
  .hljs-section,
  .hljs-selector-class {
    color: #a6e22e;
    // color: ${props => props.theme.success};
  }
  .hljs-strong {
    font-weight: bold;
  }
  .hljs-emphasis {
    font-style: italic;
  }
  .hljs-keyword,
  .hljs-selector-tag,
  .hljs-name,
  .hljs-attr {
    color: #f92672;
    // color: ${props => props.theme.danger};
  }
  .hljs-symbol,
  .hljs-attribute {
    color: #66d9ef;
  }
  .hljs-params,
  .hljs-class .hljs-title {
    color: #f8f8f2;
  }
  .hljs-string,
  .hljs-type,
  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-selector-id,
  .hljs-selector-attr,
  .hljs-selector-pseudo,
  .hljs-addition,
  .hljs-variable,
  .hljs-template-variable {
    color: #e6db74;
    // color: ${props => props.theme.warning};
  }
  .hljs-comment,
  .hljs-deletion,
  .hljs-meta {
    color: #75715e;
  }
`

const Highlight: React.FC<Props> = (props: Props) => {
  const { language, children } = props
  return (
    <StyleWrapper>
      <ReactHighlight language={language}>{children}</ReactHighlight>
    </StyleWrapper>
  )
}

export default Highlight
